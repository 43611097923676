import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../store/userSlice';
import { updateUser } from '../services/service';
import dayjs from 'dayjs';
import { Modal } from 'react-bootstrap';
import { resetPassword } from '../services/service';

const UserProfile = () => {
  const dispatch = useDispatch();
  const { userData, loading, error, dataFetched } = useSelector((state) => state.user);
  const [updatedUserData, setUpdatedUserData] = useState(null);
  const [showResetModal, setShowResetModal] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    if (!dataFetched) {
      dispatch(fetchUserData());
    }
  }, [dispatch, dataFetched]);

  useEffect(() => {
    if (userData) {
      setUpdatedUserData(userData.user);
    }
  }, [userData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUserData({
      ...updatedUserData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUser(updatedUserData).then((response) => {
        toast.success('Profile updated successfully');
        dispatch(fetchUserData());
      });
    } catch (error) {
      console.error('Failed to update profile:', error);
      toast.error('Failed to update profile. Please try again.');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error('New passwords do not match');
      return;
    }
    try {
      await resetPassword({ password: newPassword }).then(() => {
        toast.success('Password reset successfully');
        setShowResetModal(false);
      });
    } catch (error) {
      console.error('Failed to reset password:', error);
      toast.error('Failed to reset password. Please try again.');
    }
  };

  if (loading)
    return (
      <div className='container mt-5 d-flex justify-content-center align-items-center' style={{ minHeight: '75vh' }}>
        <div className='loader'></div>
      </div>
    );
  if (error)
    return (
      <div className='container mt-5 d-flex justify-content-center align-items-center' style={{ minHeight: '75vh' }}>
        <div className='alert alert-danger' role='alert'>
          {error}
        </div>
      </div>
    );
  if (!userData)
    return (
      <div className='container mt-5 d-flex justify-content-center align-items-center' style={{ minHeight: '75vh' }}>
        <div className='alert alert-danger' role='alert'>
          No user data available
        </div>
      </div>
    );

  return (
    <div className='container mt-5'>
      <div className='row'>
        <div className='col-md-6 d-flex flex-column'>
          <h1 className='display-4 mb-4'>Your Profile</h1>
          <p className='mb-5' style={{ fontSize: '1.2rem' }}>
            Manage your personal information and view your subscription details.
          </p>
        </div>
        <div className='col-md-6'>
          <div className='card p-4 shadow border-0'>
            <div className='card-body'>
              <h3 className='card-title mb-4'>Profile Information</h3>
              <form onSubmit={handleSubmit}>
                <div className='mb-3'>
                  <label htmlFor='name' className='form-label'>
                    Full Name
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    name='name'
                    value={updatedUserData?.name || ''}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='email' className='form-label'>
                    Email
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='email'
                    name='email'
                    value={updatedUserData?.email || ''}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='organization' className='form-label'>
                    Organization
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='organization'
                    name='organization'
                    value={updatedUserData?.organization || ''}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-3'>
                      <label htmlFor='subscription' className='form-label'>
                        Subscription Plan
                      </label>
                      <input
                        type='text'
                        className='form-control bg-light'
                        id='subscription'
                        value={userData?.subscription?.plan || ''}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-3'>
                      <label htmlFor='renewal_date' className='form-label'>
                        Renewal Date
                      </label>
                      <input
                        type='text'
                        className='form-control bg-light'
                        id='renewal_date'
                        value={dayjs(userData?.subscription?.renewal_date).format('DD/MM/YYYY') || ''}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-3'>
                      <label htmlFor='num_credits' className='form-label'>
                        Total Credits
                      </label>
                      <input
                        type='text'
                        className='form-control bg-light'
                        id='num_credits'
                        value={userData?.subscription?.num_credits || ''}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='mb-3'>
                      <label htmlFor='remaining_credit' className='form-label'>
                        Remaining Credits
                      </label>
                      <input
                        type='text'
                        className='form-control bg-light'
                        id='remaining_credit'
                        value={userData?.subscription?.remaining_credit || ''}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-grid gap-2 mt-4'>
                  <button type='submit' className='btn btn-dark w-100 rounded-pill'>
                    {loading ? 'Saving...' : 'Save Changes'}
                  </button>
                  <button
                    type='button'
                    className='btn btn-outline-dark w-100 rounded-pill'
                    onClick={() => setShowResetModal(true)}>
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      {/* Reset Password Modal */}
      <Modal show={showResetModal} onHide={() => setShowResetModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleResetPassword}>
            <div className='mb-3'>
              <label htmlFor='newPassword' className='form-label'>
                New Password
              </label>
              <input
                type='password'
                className='form-control'
                id='newPassword'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className='mb-3'>
              <label htmlFor='confirmPassword' className='form-label'>
                Confirm New Password
              </label>
              <input
                type='password'
                className='form-control'
                id='confirmPassword'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type='submit' className='btn btn-dark w-100 rounded-pill'>
              Reset Password
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserProfile;
