import React from 'react';
// import logo from '../assets/logo.png';

const Tasks = () => {
  return (
    <div className='tasks-container'>
      {[1, 2, 3].map((taskId) => (
        <div key={taskId} className='task-item card mb-3'>
          <div className='card-body p-0'>
            <details className='task-dropdown'>
              <summary className='task-summary card-header d-flex justify-content-between align-items-center'>
                <span>Task {taskId}</span>
                <span className='dropdown-icon'>
                  <i className='bi bi-chevron-down'></i>
                  <i className='bi bi-chevron-up'></i>
                </span>
              </summary>
              <div className='task-details card-body border-top'>
                <p className='mb-0'>
                  Task {taskId} details go here. This is a placeholder for the task description, due date, priority,
                  etc.
                </p>
              </div>
            </details>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Tasks;
