import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserData as fetchUserDataService } from '../services/service';

export const fetchUserData = createAsyncThunk('user/fetchUserData', async (_, { rejectWithValue }) => {
  try {
    const response = await fetchUserDataService();
    return response;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'An error occurred');
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: null,
    loading: false,
    error: null,
    dataFetched: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload;
        state.dataFetched = true;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { updateUserData } = userSlice.actions;
export default userSlice.reducer;
