import React, { useEffect } from 'react';
import Contact from '../components/Contact';
import Upload from '../components/Upload';
import Tasks from '../components/Tasks';
import { fetchUserData } from '../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const Home = () => {
  const dispatch = useDispatch();
  const { userData, loading, error, dataFetched } = useSelector((state) => state.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated && !dataFetched) {
      dispatch(fetchUserData());
    }
  }, [dispatch, isAuthenticated, dataFetched]);

  if (loading)
    return (
      <div className='container mt-5 d-flex justify-content-center align-items-center' style={{ minHeight: '75vh' }}>
        <div className='loader'></div>
      </div>
    );
  if (error)
    return (
      <div className='container mt-5 d-flex justify-content-center align-items-center' style={{ minHeight: '75vh' }}>
        <div className='alert alert-danger' role='alert'>
          {error}
        </div>
      </div>
    );
  if (!userData)
    return (
      <div className='container mt-5 d-flex justify-content-center align-items-center' style={{ minHeight: '75vh' }}>
        <div className='alert alert-danger' role='alert'>
          No user data available
        </div>
      </div>
    );

  return (
    <div className='container instrument-sans-font'>
      <div className='row'>
        <div className='col-4'>
          <Contact />
        </div>
        <div className='col-4'>
          <Upload />
        </div>
        <div className='col-4'>
          <Tasks />
        </div>
      </div>
    </div>
  );
};

export default Home;
