import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation, useRefreshTokenMutation } from '../services/service';
import { toast, ToastContainer } from 'react-toastify';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [refreshToken] = useRefreshTokenMutation();
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const result = await login({ email, password }).unwrap();
      if (result.error) {
        if (result.error.status === 401) {
          try {
            const refreshResult = await refreshToken().unwrap();
            if (refreshResult.access_token) {
              const retryResult = await login({ email, password }).unwrap();
              if (!retryResult.error) {
                dispatch({ type: 'auth/setCredentials', payload: { token: retryResult.access_token } });
                localStorage.setItem('token', retryResult.access_token);
                navigate('/');
                return;
              }
            }
          } catch (refreshError) {
            console.error('Failed to refresh token:', refreshError);
          }
        }
        // If refresh failed or other error occurred
        toast.error(result.error.data?.message || 'An error occurred during login');
      } else {
        dispatch({ type: 'auth/setCredentials', payload: { token: result.access_token } });
        localStorage.setItem('token', result.access_token);
        navigate('/');
      }
    } catch (err) {
      console.error('Failed to login:', err);
      toast.error('Invalid username or password');
    }
  };

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => localStorage.setItem('ip', data.ip))
      .catch((error) => console.error('Error fetching IP:', error));
  }, []);

  return (
    <>
      <div className='container mt-5'>
        <div className='row mt-5'>
          <div className='col-md-6 d-flex flex-column justify-content-center'>
            <h1 className='display-2 mb-4'>Welcome back!</h1>
            <p className='mb-5 w-75' style={{ fontSize: '1.5rem' }}>
              Log in to access your account and continue your work with our advanced medical imaging tools.
            </p>
          </div>
          <div className='col-md-6'>
            <div className='card p-4 shadow border-0'>
              <div className='card-body'>
                <h3 className='card-title mb-4'>Login</h3>
                <form onSubmit={handleLogin}>
                  <div className='mb-3'>
                    <label htmlFor='email' className='form-label'>
                      Email
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='email'
                      placeholder='Enter your email'
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='password' className='form-label'>
                      Password
                    </label>
                    <div className='input-group'>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className='form-control'
                        id='password'
                        placeholder='Enter your password'
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        className='btn btn-outline-secondary'
                        type='button'
                        onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </div>
                  <button type='submit' className='btn btn-dark w-100 rounded-pill mt-4'>
                    {isLoading ? 'Logging in...' : 'Login'}
                  </button>
                </form>
                <div className='d-flex mt-2 justify-content-between'>
                  <div className='text-start'>
                    <p>
                      Don't have an account?{' '}
                      <Link to='/register' className='text-decoration-none'>
                        Sign up!
                      </Link>
                    </p>
                  </div>
                  <div className='text-end'>
                    <Link to='/forgot-password' className='text-decoration-none'>
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default LoginPage;
