/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaFileUpload, FaFolderOpen } from 'react-icons/fa';
import JSZip from 'jszip';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';

const MultipleFilesDropzone = ({ onFilesAdded }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFilesAdded(acceptedFiles);
    },
    [onFilesAdded],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    noDirectory: true,
  });

  return (
    <div
      {...getRootProps()}
      className={`dropzone ${isDragActive ? 'active' : ''}`}
      style={{
        border: '2px dashed #222222',
        borderRadius: '10px',
        padding: '30px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#fff',
        transition: 'all 0.3s ease',
        marginBottom: '20px',
      }}>
      <input {...getInputProps()} />
      <FaFileUpload size={40} color='#222222' />
      <div className='mt-3'>
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop multiple files here, or click to select files</p>
        )}
      </div>
    </div>
  );
};

const FoldersDropzone = ({ onFilesAdded }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFilesAdded(acceptedFiles);
    },
    [onFilesAdded],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    noDrag: false,
    directoryMode: true,
  });

  return (
    <div
      {...getRootProps()}
      className={`dropzone ${isDragActive ? 'active' : ''}`}
      style={{
        border: '2px dashed #222222',
        borderRadius: '10px',
        padding: '30px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#f8f9fa',
        transition: 'all 0.3s ease',
      }}>
      <input {...getInputProps()} webkitdirectory='' mozdirectory='' />
      <FaFolderOpen size={40} color='#222222' />
      <div className='mt-3'>
        {isDragActive ? (
          <p>Drop the folders here ...</p>
        ) : (
          <p>Drag 'n' drop folders here, or click to select folders</p>
        )}
      </div>
    </div>
  );
};

const UploadSection = () => {
  const [allFiles, setAllFiles] = useState([]);
  const token = localStorage.getItem('authToken');

  // if (!user || !token) {
  //   return <p>You must be logged in to upload files.</p>;
  // }

  const { username } = 'user';

  const handleFilesAdded = (newFiles) => {
    setAllFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const uploadFiles = async () => {
    const zip = new JSZip();
    allFiles.forEach((file) => {
      zip.file(file.name, file);
    });

    try {
      const content = await zip.generateAsync({ type: 'blob' });
      const zipBlob = new Blob([content], { type: 'application/zip' });

      const storageRef = ref(storage, `uploads/${username}/archive.zip`);
      const uploadTask = uploadBytesResumable(storageRef, zipBlob);

      uploadTask.on(
        'state_changed',
        (snapshot) => {},
        (error) => {
          console.error('Upload failed:', error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            const data = {
              url: downloadURL,
              username,
              token,
            };

            // await sendSegmenta(data).then((response) => {
            //   console.log(response);
            // });
          } catch (error) {
            console.error('Error getting download URL:', error);
          }
        },
      );
    } catch (error) {
      console.error('Error generating zip file:', error);
    }
  };

  return (
    <div className='upload d-flex flex-column justify-content-center' style={{ height: '75vh' }}>
      <MultipleFilesDropzone onFilesAdded={handleFilesAdded} />
      <FoldersDropzone onFilesAdded={handleFilesAdded} />
      <button
        className='btn btn-dark'
        onClick={(e) => {
          e.preventDefault();
          uploadFiles();
        }}>
        Upload Files
      </button>
    </div>
  );
};

export default UploadSection;
