import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useRegisterMutation } from '../services/service';
import { useLoginMutation } from '../services/service';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Register = () => {
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    password: '',
    organization: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [register, { isLoading }] = useRegisterMutation();
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await register(formData).unwrap();
      if (result) {
        await login({
          email: formData.email,
          password: formData.password,
        }).unwrap();
        if (isLoginLoading) {
          toast.warning('Please wait while we log you in...');
        }
      }
      dispatch({ type: 'auth/setCredentials', payload: { token: result.access_token } });
      localStorage.setItem('token', result.access_token);
      navigate('/');
    } catch (error) {
      console.error('Error during registration or login:', error);
    }
  };

  return (
    <>
      <div className='container mt-5'>
        <div className='row mt-5'>
          <div className='col-md-6 d-flex flex-column justify-content-center'>
            <h1 className='display-2 mb-4'>Let's set up a demo account for you, today.</h1>
            <p className='mb-5 w-75' style={{ fontSize: '2rem' }}>
              After you fill out the form, we will set-up a demo account for the products you requested. You will be
              able to access your account in 24 hours.
            </p>
          </div>
          <div className='col-md-6'>
            <form onSubmit={handleSubmit}>
              <div className='mb-3'>
                <label htmlFor='full_name' className='form-label'>
                  Name
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='full_name'
                  name='full_name'
                  value={formData.full_name}
                  onChange={handleChange}
                  placeholder='Enter your name...'
                  required
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='email' className='form-label'>
                  Email
                </label>
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  name='email'
                  value={formData.email}
                  placeholder='Enter your email...'
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='password' className='form-label'>
                  Password
                </label>
                <div className='input-group'>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className='form-control'
                    id='password'
                    name='password'
                    value={formData.password}
                    placeholder='Enter your password...'
                    onChange={handleChange}
                    minLength={8}
                    maxLength={32}
                    pattern='(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,32}'
                    title='Password must be 8-32 characters long, contain at least one number, one uppercase letter, one lowercase letter, and one special character.'
                    required
                  />
                  <button
                    className='btn btn-outline-secondary'
                    type='button'
                    onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </div>
              <div className='mb-3'>
                <label htmlFor='organization' className='form-label'>
                  Organization
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='organization'
                  name='organization'
                  value={formData.organization}
                  onChange={handleChange}
                  placeholder='Enter your organization...'
                />
              </div>
              <div className='d-flex'>
                <button type='submit' className='btn btn-dark w-25 rounded-pill mt-2' disabled={isLoading}>
                  {isLoading ? 'Registering...' : 'Register'}
                </button>
                <div className='mt-3 ms-3 text-center'>
                  <p>
                    Already have an account?{' '}
                    <Link to='/login' className='text-primary text-decoration-none'>
                      Back to Login
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Register;
