import React from 'react';
import { BsFillQuestionOctagonFill } from 'react-icons/bs';

const Contact = () => {
  return (
    <div className='contact d-flex flex-column justify-content-center' style={{ height: '75vh' }}>
      <h2>Need a help?</h2>
      <p>Contact us and we will answer any of your question!</p>
      <p className='d-flex align-items-center'>
        <BsFillQuestionOctagonFill className='me-1' style={{ zoom: '2' }} />
        <span className='mx-1'>Feel free to contact us</span>
        <a
          href='mailto:hello@mogram.com'
          style={{
            color: 'black',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
          target='_blank'
          rel='noopener noreferrer'>
          hello@mogram.com
        </a>
      </p>
    </div>
  );
};

export default Contact;
