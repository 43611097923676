import React from 'react';
import logo from '../assets/logo.png';
import { useLogoutMutation } from '../services/service';
import { useSelector } from 'react-redux';

const Navbar = () => {
  const [logout, { isLoading }] = useLogoutMutation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated) || localStorage.getItem('token');
  const userData = useSelector((state) => state.user.userData);

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('ip');
      window.location.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <nav className='navbar container instrument-sans-font d-flex justify-content-between align-items-center'>
      <a className='navbar-brand' href='/'>
        <img
          src={logo}
          alt='Segmenta logo'
          style={{
            width: '200px',
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      </a>
      {isAuthenticated && (
        <>
          <div className='d-flex align-items-center'>
            {userData?.user?.name && <p className='pt-3 me-1'>Welcome {userData?.user?.name || ''}!</p>}
            <button className='btn p-0 me-3' onClick={() => (window.location.href = '/profile')}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                fill='currentColor'
                className='bi bi-person-circle'
                viewBox='0 0 16 16'>
                <path d='M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z' />
                <path
                  fillRule='evenodd'
                  d='M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z'
                />
              </svg>
            </button>
            <button
              className='btn btn-outline-dark'
              onClick={(e) => {
                e.preventDefault();
                handleLogout();
              }}>
              {isLoading ? 'Logging out...' : 'Logout'}
            </button>
          </div>
        </>
      )}
    </nav>
  );
};

export default Navbar;
