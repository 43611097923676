import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBl4L1cBqy_D0ppeet33ya294dTjI6NQlA',
  authDomain: 'segmenta-files.firebaseapp.com',
  projectId: 'segmenta-files',
  storageBucket: 'segmenta-files.appspot.com',
  messagingSenderId: '106122907643',
  appId: '1:106122907643:web:73355a37d7c561b08ec55e',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
