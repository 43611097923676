import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchListOfPlans } from '../services/service';

const Plans = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);

  const handleSubscribe = (planName) => {
    if (isAuthenticated) {
      // TODO: Implement subscription logic
      console.log(`Subscribing to ${planName} plan`);
    } else {
      navigate('/login');
    }
  };

  const fetchPlans = async () => {
    const response = await fetchListOfPlans();
    setPlans(response?.plans);
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  return (
    <div className='container mt-5'>
      <h2 className='text-center mb-5 display-4 fw-bold'>Choose Your Plan</h2>
      <div className='row justify-content-center'>
        {plans?.map((plan, index) => (
          <div key={index} className='col-lg-3 col-md-6 mb-4'>
            <div className={`card h-100 shadow-sm ${plan.product === 'Platinum Plan' ? 'border-dark border-2' : ''}`}>
              {plan.product === 'Platinum Plan' && (
                <div className='card-header bg-dark text-white text-center py-2'>Best Value</div>
              )}
              <div className='card-body d-flex flex-column p-4'>
                <h4 className='card-title text-center mb-3'>{plan.product}</h4>
                <h5 className='card-subtitle mb-4 text-center text-muted'>
                  {plan.price === 0 ? 'Free' : `${plan.price} ${plan.currency || ''} / ${plan.interval}`}
                </h5>
                <ul className='list-unstyled mt-3 mb-4'>
                  <li className='mb-2'>
                    <i className='bi bi-check-circle-fill text-success me-2'></i>
                    {plan.description}
                  </li>
                  <li className='mb-2'>
                    <i className='bi bi-check-circle-fill text-success me-2'></i>
                    {plan.num_credits} credits
                  </li>
                  {plan.trial_period_days > 0 && (
                    <li className='mb-2'>
                      <i className='bi bi-check-circle-fill text-success me-2'></i>
                      {plan.trial_period_days} days free trial
                    </li>
                  )}
                </ul>
                <button
                  className={`btn ${
                    plan.product === 'Platinum Plan' ? 'btn-dark' : 'btn-outline-dark'
                  } w-100 rounded-pill mt-auto py-2`}
                  onClick={() => handleSubscribe(plan.product)}>
                  {plan.price === 0 ? 'Start Free' : 'Subscribe Now'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
